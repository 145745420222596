// Import Hamburger library and only few types from   https://github.com/jonsuh/hamburgers
$hamburger-types: (arrow,vortex,vortex-r);
@import '~hamburgers/_sass/hamburgers/hamburgers';

.asd_menu_icon {
  display: none;
}

@media screen and ( max-width: $screen-laptop ) {
  .asd_menu_icon {

    box-shadow: #000 0 0 30px -10px, inset #000 0 0px 22px -11px;

    z-index: 10001;
    position: fixed;

    top: 20px;
    right: 15px;
    width: 60px;
    height: 45px;

    padding: 0;

    @media screen and (max-width: $screen-mobile) {
      right: 10px;
      width: 45px;

      .hamburger-inner {

        width: 28px;
        &::before, &::after {
          width: 28px;
        }

      }
    }

    //noinspection CssUnknownTarget
    background-size: cover;

    border: 5px solid #ffffff;
    border-radius: 0.25em;
    //outline: 1px solid #000;

    display: flex;
    align-self: center;

    // Button style to override browser default and mini.css
    > button {
      background-color: transparent;
      color: white;
      padding: 10px 10px;
      width: 100%;

      &:hover, &:focus {
        background-color: transparent;
      }

      .hamburger-inner {
        background-color: #fff;

        &::before, &::after {
          background-color: #fff;
        }
      }

      // when menu is open
      &.is-active {

        background-color: rgba(255, 255, 255, 0.5);

        .hamburger-inner {
          background-color: $color-primary;

          &::before, &::after {
            background-color: $color-primary;
          }
        }

        // when focused or hovered
        &:hover, &:focus {
          background-color: rgba(255, 255, 255, 1);

          .hamburger-inner {
            background-color: #000;

            &::before, &::after {
              background-color: #000;
            }
          }
        }

      }
    }//button
  }
}
