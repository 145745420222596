$spacing: 20px;

$color-primary: #14683d;


$color-secondary: #8abb47;

$color-white: #ffffff;
$color-black: #000000;
$color-red: #ff0000;

$navbarOpenHeight: 100px;

/** Screen size */
$screen-mobile: 768px;
$screen-laptop: 992px;
$screen-computer: 1200px;

/** Card */
$card-image-height: 300px;
$card-image-update: 50px;
$card-text-height: 300px;


$card-first-height: 300px;
$card-text-margin: 230px;

$card-margin: 10px;
$card-border: 3px;
$card-border-radius: 10px;

/* Schede */
$border-schede: 1px;
$border-scheda: 0px;
$padding-schede: 5px;
$margin-scheda: 5px;
$margin-modal-10: 10px;

$padding-modal-0: 0;
$padding-modal-10: 10px;
$padding-modal-20: 20px;

$base-font-family: '"Merriweather", Georgia, "Times New Roman", Times, serif';
$fore-color: #fff;
//$back-color: $color-primary;
$secondary-back-color: $color-secondary;
$a-link-color: #fff;
$a-visited-color: darken($fore-color, 5%);
