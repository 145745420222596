body {

  > .wrapper {

    > main {

      // XXX main cause of scrolling problem in x axys is because of this tag content.
      overflow: hidden;
      margin-top: 60px;
      padding: (2* $spacing) 0;

      * {
        text-shadow: #3336 0 0 6px;
      }

      > * {
        width: 100%;
      }

      > .content-wrapper {

        width: 90%;
        max-width: 1600px;

        margin: 0 auto;


      }

    }

    > .waves-container {
      padding: 0;

      > .padd {
        margin: (2 * $spacing) 0;
      }
    }

  }

  &.contattaci {
    > .wrapper {
      > main {
        padding-bottom: 0;

        .asd-category .asd-article {

          * {
            text-align: center !important;
          }

        }
      }
    }
  }

}
