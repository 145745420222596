/**
 * Menu
 */
nav.menu {

  * {
    font-family: $font-family-title;
    text-transform: lowercase;
  }

    background-color: #333;
    transition: all 0.4s;
    //z-index: -10;


    box-shadow: inset #000 0 0 50px -25px;

    padding: 20px 0;
    box-sizing: border-box;

    //@include df;
    align-content: flex-start;

    ul {
      list-style-type: none;
      display: flex;
      padding: 0;
    }

    @media screen and (max-width: $screen-laptop) {

      ul {
        flex-wrap: wrap;
        margin: 0;
        align-content: flex-start;

        li {
          width: 100%;

          a, span {
            color: #fff;
            width: 100%;
            padding: 0 20px;
            line-height: 50px;
          }

          &.parent {
            background-color: #666;
            box-shadow: inset #000 0 0 50px -25px;

            ul {
              padding-left: 20px;
            }
          }
        }
      }
    }


    @media screen and (min-width: $screen-laptop+1) {

      z-index: 2;

      top: 0;
      width: 100%;
      height: 60px;

      padding: 0 50px;
      box-sizing: border-box;

      display: flex;

      white-space: nowrap;

      background-color: #efefef;

      ul {
        margin: 5px auto;

        li {
          display: flex;
          flex-wrap: wrap;
          align-items: center;

          box-sizing: border-box;
          margin: 0 10px;
          padding: 0;

          height: 100%;

          border-radius: 1em;

          &:hover {
            background-color: #ccc;
          }

          span, a {
            color: #000;
            text-decoration: none;
            line-height: 50px;
            padding: 0 10px;
            text-align: center;

            &:hover {
              cursor: pointer;

            }
          }

          &.parent {

            div {
              display: none;
              width: 100%;
            }

            &:hover {

              div {
                display: block;
                position: absolute;
                top: 54px;
                width: 200px;
                margin-left: -70px;
                z-index:3;
                padding: 12px;
                box-sizing: border-box;
              }

              ul {
                width: 100%;
                background-color: #000;
                border-radius: 1rem;

                padding: 15px;

                color: #fff;
                flex-wrap: wrap;

                a {
                  color: #fff;
                  width: 100%;
                  text-decoration: none;


                  &:hover {
                    cursor: pointer;
                    color: #000;
                  }
                }

                > li {
                  margin: 10px 0;
                  width: 100%;
                  display: flex;

                  &:hover {
                    background-color: #fff;
                  }
                }

              }
            }
          }
        }
      }

    }
  }

body.asd-op-nav {
  nav.menu {
    @media screen and (min-width: $screen-laptop+1) {
      transform: translate3d(0, 60vh, 0);
    }
  }
}
