.modale-wrapper {

  .modale {
    position: fixed;
    z-index: 10001;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    overflow: auto;
    background-color: $color-black;

    animation: modale 0.5s;

    &.closed {
      z-index: -3;
      opacity: 0;
    }

    &.closing {
      animation: modale 1s;
      animation-direction: reverse;
      animation-fill-mode: forwards;
    }

    .close {
      position: fixed;
      right: 20px;
      bottom: 30px;
      color: $color-red;
      font-size: 40px;
      font-weight: bold;
      transition: 0.3s;
      z-index: 10002;

      &:hover, &:focus {
        text-decoration: none;
        cursor: pointer;
      }
    }

    .no-block {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      height: 100%;

      @media screen and (min-width: $screen-mobile) {
        flex-wrap: nowrap;
      }

      .modal-card {
        width: 100%;
        padding: $padding-modal-10 $padding-modal-0;

        @media screen and (min-width: $screen-mobile) {
          width: 50%;
        }

        @media screen and (min-width: $screen-computer) {
          width: 66.6%;
        }

        .img-big {
          padding: $padding-modal-10 $padding-modal-20;

          @media screen and (min-width: $screen-mobile) {
            padding: $padding-modal-10 $padding-modal-0 $padding-modal-10 $padding-modal-20;

            img {
              width: 100%;
            }
          }
        }

        .img-mini {
          //margin: $margin-modal-img;

          @media screen and (min-width: $screen-laptop) {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
          }

          .single-image {
            padding: $padding-modal-10 $padding-modal-20;

            @media screen and (min-width: $screen-mobile) {
              padding: $padding-modal-10 $padding-modal-0 $padding-modal-10 $padding-modal-20;
            }

            @media screen and (min-width: $screen-laptop) {
              width: calc(50% - #{$padding-modal-20});
            }

            .img-piccola {

              //width: calc(50% - 2*#{$margin-modal-img});

            }
          }
        }

        .title {
          color: $color-white;
          text-align: center;
          top: 15px;
        }

        .article {
          margin: 15px;
          overflow-y: auto;
          color: $color-white;

          @media screen and (min-width: $screen-computer) {
            overflow-y: unset;
          }
        }
      }

      .right {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding-top: $margin-modal-10;

        @media screen and (min-width: $screen-computer) {
          width: 33.3%;
        }
      }
    }
  }

}


form.asd-contact {
  background: none;
  border: none;
  padding: 0;

  > div {

    position: relative;
    overflow: hidden;

    max-width: 550px;
    @media screen and (min-width: $screen-laptop) {
      max-width: 950px;
    }


    //box-shadow: #000 0 15px 70px -15px;
    //border-radius: 1em;
    //border: 2px solid #fff;

    width: 100%;
    padding: $spacing;
    box-sizing: border-box;
    margin: 0 auto;

    @media screen and (min-width: $screen-laptop) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: $spacing 6*$spacing;
    }

    > * {

      width: 100%;
      margin-bottom: 20px;

    }

    header {
      text-align: center;
    }


    .field {
      margin: 0 auto $spacing;
      width: 90%;
      //margin: 0 auto;

      > .l {
        text-transform: capitalize;
        text-align: center;
        margin: 0 auto 5px;
      }

      > .i {

        > * {
          width: 100%;
          box-sizing: border-box;
          padding: 10px $spacing;

          background-color: transparent;
          border: 2px solid #fff;
          border-radius: 8px;
          color: #fff;
        }

        @media screen and (min-width: $screen-laptop) {
          > textarea {
            height: 210px;
          }
        }

      }

      &.checkbox {
        display: flex;

        //@media screen and (max-width: $screen-mobile) {
        flex-wrap: wrap;
        //}

        > .i {
          width: 40px;
        }

        > .l {
          $w_l: "100% - 40px";
          width: calc(#{$w_l});
          text-align: left;

          label {
            width: 100%;
            height: 100%;
            display: block;
          }
        }

        > .info {
          width: 100%;
          text-align: right;

          a {
            font-size: 0.7rem;
            text-decoration: underline;
            cursor: pointer;
          }
        }

      }
      .error_message {
        color: red;
      }
      .error {
        border-color: red;
      }
    }

    input, textarea {
      -webkit-appearance:none;



      width: 100%;
      line-height: 150%;
      margin: 0;

      @media screen and (max-width: $screen-laptop) {
        box-shadow: #fff 0 0 15px -5px;
      }


      &::placeholder {
        color: #ccc
      }

      transition: all 0.2s ease-in-out;
      &:hover {
        box-shadow: inset #fff 0 0 15px -5px;
      }

      &:focus {
        outline: unset;

        @media screen and (max-width: $screen-laptop) {
          box-shadow: #fff 0 0 30px -5px;
        }

        box-shadow: #fff 0 0 15px -5px;
        &::placeholder {
          color: #AAA;
        }
        //background-color: lighten($color-primary, 30%);
      }
    }

    input[type='checkbox'] {
      -webkit-appearance:none;
      width: 25px !important;
      height: 25px !important;
      border: 1px solid #000;
      border-radius: 0.25em;
      background-color: #fff;
      padding: 0 !important;
      margin-top: 1px;

      &:hover {
        cursor: pointer;
      }

      &:focus {
        outline-color: unset;
        background-color: lighten($color-primary, 20%);
      }

      &:checked {
        background: darken($color-primary, 20%);
      }


    }


    button[type='submit'] {
      margin: 0 25%;
      padding: 10px 20px;
      width: 50%;
      min-height: 40px;
      background-color: #fff;
      outline: none;
      border: none;
      border-radius: 0.25em;
      transition: box-shadow 0.2s;

      &:hover {
        cursor: pointer;
        box-shadow: #ffffff 0 0 20px -5px;
      }
    }


    .parquet {
      position: absolute;
      top: 0;
      left: 0;
    }

    .captcha {
      section > * {
        margin: 0 auto;
      }
    }

    > .message, .error {
      margin: 0 auto 15px;
      padding: 10px;
      border-radius: 5px;
      max-width: 500px;
    }

    > .message {
      background-color: #ccf7e2;
    }

    > .error {
      background-color: #f79c96;
    }



  }

  .modale-wrapper {
    .modal-card {
      width: 100%;
      padding: 5vh 10vw !important;
      @media screen and (min-width: $screen-mobile) {
        width: 100% !important;
        padding: 5vh 10vw !important;
      }
    }
  }
}


