//  This is a flavor file. Duplicate it and edit it to create your own flavor. Read instructions carefully.
//  Single-line comments, starting with '//' will not be included in your final CSS file. Multiline comments,
//  structured like the flavor description below, will be included in your final CSS file.
/*
  Flavor name: Default (mini-default)
  Author: Angelos Chalaris (chalarangelo@gmail.com)
  Maintainers: Angelos Chalaris
  mini.css version: v3.0.1
*/
@import '../../../node_modules/mini.css/src/mini/core';
@import '../../../node_modules/mini.css/src/mini/layout';

/*
  Custom elements for card elements.
*/
$card-small-name:              'small';            // Class name for small cards.
$card-small-width:             240px;              // Width for small cards.
@include make-card-alt-size ($card-small-name, $card-small-width);

$card-large-name:              'large';            // Class name for large cards.
$card-large-width:             480px;              // Width for large cards.
@include make-card-alt-size ($card-large-name, $card-large-width);

$card-fluid-name:              'fluid';            // Class name for fluid cards.
$card-fluid-width:             100%;               // Width for fluid cards.
@include make-card-alt-size ($card-fluid-name, $card-fluid-width);

$card-warning-name:            'warning';          // Class name for card warnging color variant.
$card-warning-back-color:      #ffca28;            // Background color for card warnging color variant.
$card-warning-fore-color:      #111;               // Text color for card warnging color variant.
$card-warning-border-color:    #e8b825;            // Border style for card warnging color variant.
@include make-card-alt-color ($card-warning-name, $card-warning-back-color, $card-warning-fore-color, $card-warning-border-color);

$card-error-name:              'error';            // Class name for card error color variant.
$card-error-back-color:        #b71c1c;            // Background color for card error color variant.
$card-error-fore-color:        #f8f8f8;            // Text color for card error color variant.
$card-error-border-color:      #a71a1a;            // Border style for card error color variant.
@include make-card-alt-color ($card-error-name, $card-error-back-color, $card-error-fore-color, $card-error-border-color);

$card-section-dark-name:        'dark';            // Class name for card dark section variant.
$card-section-dark-back-color:  #e0e0e0;           // Background color for card dark section variant.
$card-section-dark-fore-color:  #111;              // Text color for card dark section variant.
@include make-card-section-alt-color ($card-section-dark-name, $card-section-dark-back-color, $card-section-dark-fore-color);

$card-section-double-padded-name:    'double-padded';   // Class name for card double-padded section variant.
$card-section-double-padded-padding:  calc(1.5 * var(#{$universal-padding-var}));  // Padding for card sectiondouble-padded section variant.
@include make-card-section-alt-style ($card-section-double-padded-name, $card-section-double-padded-padding);

//@import '../../../node_modules/mini.css/src/mini/input_control';
$input-group-name:           'input-group' !default;  // Class name for input groups.
$_include-fluid-input-group: true !default;           // [Hidden] Should fluid input groups be included? (boolean)
$input-group-fluid-name:     'fluid' !default;        // Class name for fluid input groups.
$input-group-vertical-name:  'vertical' !default;     // Class name for vertical input groups.
$input-group-mobile-breakpoint:   767px !default;     // Breakpoint for fluid input group mobile view.
$button-class-name:           'button' !default;      // Class name for elements styled as buttons.
$input-disabled-opacity:      0.75 !default;          // Opacity for input elements when disabled.
$button-group-name:         'button-group' !default;  // Class name for button groups.
$button-group-mobile-breakpoint: 767px !default;      // Mobile breakpoint for button groups.
$form-back-color:             #f0f0f0 !default;       // Background color for forms.
$form-fore-color:             #111 !default;          // Text color for forms.
$form-border-color:           #ddd !default;          // Border color for forms.
$input-back-color:            #f8f8f8 !default;       // Background color for input elements.
$input-fore-color:            #111 !default;          // Text color for input elements.
$input-border-color:          #ddd !default;          // Border color for input elements.
$input-focus-color:           #0288d1 !default;       // Border color for focused input elements.
$input-invalid-color:         #d32f2f !default;       // Border color for invalid input elements.
$button-back-color:           #e2e2e2 !default;       // Background color for buttons.
$button-hover-back-color:     #dcdcdc !default;       // Background color for buttons (hover).
$button-fore-color:           #212121 !default;       // Text color for buttons.
$button-border-color:         transparent !default;   // Border color for buttons.
$button-hover-border-color:   transparent !default;   // Border color for buttons (hover).
$button-group-border-color:  rgba(124,124,124, 0.54) !default;  // Border color for button groups.
// CSS variable name definitions [exercise caution if modifying these]
$form-back-color-var:         '--form-back-color' !default;
$form-fore-color-var:         '--form-fore-color' !default;
$form-border-color-var:       '--form-border-color' !default;
$input-back-color-var:        '--input-back-color' !default;
$input-fore-color-var:        '--input-fore-color' !default;
$input-border-color-var:      '--input-border-color' !default;
$input-focus-color-var:       '--input-focus-color' !default;
$input-invalid-color-var:     '--input-invalid-color' !default;
$button-back-color-var:       '--button-back-color' !default;
$button-hover-back-color-var: '--button-hover-back-color' !default;
$button-fore-color-var:       '--button-fore-color' !default;
$button-border-color-var:       '--button-border-color' !default;
$button-hover-border-color-var: '--button-hover-border-color' !default;
$button-group-border-color-var: '--button-group-border-color' !default;
// == Uncomment below code if this module is used on its own ==
//
// $base-font-size:          1rem !default;        // Font sizing for all elements
// $universal-margin:        0.5rem !default;      // Universal margin for the most elements
// $universal-padding:       0.5rem !default;      // Universal padding for the most elements
// $universal-border-radius: 0.125rem !default;    // Universal border-radius for most elements
// $universal-box-shadow:    none !default;        // Universal box-shadow for most elements
// $universal-margin-var:          '--universal-margin' !default;
// $universal-padding-var:         '--universal-padding' !default;
// $universal-border-radius-var:   '--universal-border-radius' !default;
// $universal-box-shadow-var:      '--universal-box-shadow' !default;
// :root {
//  #{$universal-margin-var}: $universal-margin;
//  #{$universal-padding-var}: $universal-padding;
//  #{$universal-border-radius-var}: $universal-border-radius;
//  @if $universal-box-shadow != none {
//   #{$universal-box-shadow-var}: $universal-box-shadow;
//  }
// }
//
// ============================================================
// Check the `_input_control_mixins.scss` file to find this module's mixins.
@import '../../../node_modules/mini.css/src/mini/input_control_mixins';
/* Input_control module CSS variable definitions */
:root {
  #{$form-back-color-var}: $form-back-color;
  #{$form-fore-color-var}: $form-fore-color;
  #{$form-border-color-var}: $form-border-color;
  #{$input-back-color-var}: $input-back-color;
  #{$input-fore-color-var}: $input-fore-color;
  #{$input-border-color-var}: $input-border-color;
  #{$input-focus-color-var}: $input-focus-color;
  #{$input-invalid-color-var}: $input-invalid-color;
  #{$button-back-color-var}: $button-back-color;
  #{$button-hover-back-color-var}: $button-hover-back-color;
  #{$button-fore-color-var}: $button-fore-color;
  #{$button-border-color-var}: $button-border-color;
  #{$button-hover-border-color-var}: $button-hover-border-color;
  #{$button-group-border-color-var}: $button-group-border-color;
}

/*
  Custom elements for forms and input elements.
*/
$button-primary-name:            'primary';   // Class name for primary button color variant.
$button-primary-back-color:      #1976d2;     // Background color for primary button color variant.
$button-primary-hover-back-color:#1565c0;     // Background color for primary button color variant (hover).
$button-primary-fore-color:      #f8f8f8;     // Text color for primary button color variant.
@include make-button-alt-color ($button-primary-name, $button-primary-back-color, $button-primary-hover-back-color, $button-primary-fore-color);

$button-secondary-name:            'secondary'; // Class name for secondary button color variant.
$button-secondary-back-color:      #d32f2f;     // Background color for secondary button color variant.
$button-secondary-hover-back-color:#c62828;     // Background color for secondary button color variant (hover).
$button-secondary-fore-color:      #f8f8f8;     // Text color for secondary button color variant.
@include make-button-alt-color ($button-secondary-name, $button-secondary-back-color, $button-secondary-hover-back-color, $button-secondary-fore-color);

$button-tertiary-name:            'tertiary';  // Class name for tertiary button color variant.
$button-tertiary-back-color:      #308732;     // Background color for tertiary button color variant.
$button-tertiary-hover-back-color:#277529;     // Background color for tertiary button color variant (hover).
$button-tertiary-fore-color:      #f8f8f8;     // Text color for tertiary button color variant.
@include make-button-alt-color ($button-tertiary-name, $button-tertiary-back-color, $button-tertiary-hover-back-color, $button-tertiary-fore-color);

$button-inverse-name:            'inverse';   // Class name for inverse button color variant.
$button-inverse-back-color:      #212121;     // Background color for inverse button color variant.
$button-inverse-hover-back-color:#111;        // Background color for inverse button color variant (hover).
$button-inverse-fore-color:      #f8f8f8;     // Text color for inverse button color variant.
@include make-button-alt-color ($button-inverse-name, $button-inverse-back-color, $button-inverse-hover-back-color, $button-inverse-fore-color);

$button-small-name:    'small';              // Class name, padding and margin for small button size variant.
$button-small-padding: calc(0.5 * var(#{$universal-padding-var})) calc(0.75 * var(#{$universal-padding-var}));
$button-small-margin:  var(#{$universal-margin-var});
@include make-button-alt-size ($button-small-name, $button-small-padding, $button-small-margin);

$button-large-name:    'large';              // Class name, padding and margin for large button size variant.
$button-large-padding: calc(1.5 * var(#{$universal-padding-var})) calc(2 * var(#{$universal-padding-var}));
$button-large-margin:  var(#{$universal-margin-var});
@include make-button-alt-size ($button-large-name, $button-large-padding, $button-large-margin);

//@import '../../../node_modules/mini.css/src/mini/navigation';
@import '../../../node_modules/mini.css/src/mini/table';
@import '../../../node_modules/mini.css/src/mini/contextual';

/*
  Custom elements for contextual background elements, toasts and tooltips.
*/
$mark-secondary-name:            'secondary'; // Class name for secondary <mark> color variant.
$mark-secondary-back-color:      #d32f2f;     // Background color for secondary <mark> color variant.
@include make-mark-alt-color ($mark-secondary-name, $mark-secondary-back-color);

$mark-tertiary-name:            'tertiary';  // Class name for tertiary <mark> color variant.
$mark-tertiary-back-color:      #308732;     // Background color for tertiary <mark> color variant.
@include make-mark-alt-color ($mark-tertiary-name, $mark-tertiary-back-color);

$mark-tag-name:                 'tag';       // Class name, padding and border radius for tag <mark> size variant.
$mark-tag-padding:      calc(var(#{$universal-padding-var})/2) var(#{$universal-padding-var});
$mark-tag-border-radius:        1em;
@include make-mark-alt-size ($mark-tag-name, $mark-tag-padding, $mark-tag-border-radius);

@import '../../../node_modules/mini.css/src/mini/progress';

/*
  Custom elements for progress bars and spinners.
*/
$progress-primary-name:         'primary';   // Class name for primary <progress> color variant.
$progress-primary-fore-color:   #1976d2;     // Foreground color for primary <progress> color variant.
@include make-progress-alt-color ($progress-primary-name, $progress-primary-fore-color);

$progress-secondary-name:     'secondary';   // Class name for secondary <progress> color variant.
$progress-secondary-fore-color: #d32f2f;     // Foreground color for secondary <progress> color variant.
@include make-progress-alt-color ($progress-secondary-name, $progress-secondary-fore-color);

$progress-tertiary-name:       'tertiary';   // Class name for tertiary <progress> color variant.
$progress-tertiary-fore-color:   #308732;    // Foreground color for tertiary <progress> color variant.
@include make-progress-alt-color ($progress-tertiary-name, $progress-tertiary-fore-color);

$spinner-donut-primary-name:         'primary';   // Class name for primary spinner donutcolor variant.
$spinner-donut-primary-fore-color:   #1976d2;     // Foreground color for primary spinner donut color variant.
@include make-spinner-donut-alt-color ($spinner-donut-primary-name, $spinner-donut-primary-fore-color);

$spinner-donut-secondary-name:     'secondary';   // Class name for secondary spinner donut color variant.
$spinner-donut-secondary-fore-color: #d32f2f;     // Foreground color for secondary spinner donut color variant.
@include make-spinner-donut-alt-color ($spinner-donut-secondary-name, $spinner-donut-secondary-fore-color);

$spinner-donut-tertiary-name:       'tertiary';   // Class name for tertiary spinner donut color variant.
$spinner-donut-tertiary-fore-color:   #308732;    // Foreground color for tertiary spinner donut color variant.
@include make-spinner-donut-alt-color ($spinner-donut-tertiary-name, $spinner-donut-tertiary-fore-color);

@import '../../../node_modules/mini.css/src/mini/icon';
@import '../../../node_modules/mini.css/src/mini/utility';
