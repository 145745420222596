.item-page {

  .page-header {
    text-align: center;
    margin: $spacing 0;
  }

  div[itemprop="articleBody"] {
    width: 90%;
    margin: 0 auto;
  }

  > .image {
    width: 100%;
    text-align: center;
    padding: 2 * $spacing;

    img, svg {
      max-width: 100%;
      max-height: 40vh;
      filter: drop-shadow(#000A 0 0 25px);
    }
  }
}

p.readmore {
  color: $color-primary;
  font-family: $font-family-title;
  margin: $spacing auto;
  text-align: center;

  span, a {
    color: inherit;
    font-family: inherit;
    text-transform: lowercase;

    padding: 10px 10px;
    background-color: #fff;
    border-radius: 2em;

    transition: all 0.3s;
    &:hover {
      box-shadow: #000A 0 0 25px -5px;
    }
  }

  a {
    text-decoration: none;

    &:visited,
    &:hover,
    &:active {
      color: inherit;
    }
  }
}
