// TODO remove after understand what's going on
.waves-container {
  //border: 2px solid white;

  &.no-wave {
    //border: 2px solid black;
  }
}

$height: $main-article-height * 3;

$base-top: 100vh;

body > .wrapper {
  position: relative;
}

.asd_back {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;

  background: green;
  background: linear-gradient( 180deg, #14683d 0%, #8abb47 120vh);

  overflow: hidden;

  > .wa {
    position: absolute;
    left: 0;
    width: 100%;

    transition: all 0.4s;

    //noinspection CssUnknownTarget
    mask: url('../images/waves-even.svg');
    mask-type: luminance;
    //mask-size: 2560px 10000px;
    mask-size: 100% 80000px;
    mask-position: 50% 0;

    &:nth-child(odd) {
      //noinspection CssUnknownTarget
      mask: url('../images/waves-odd.svg');
      mask-type: luminance;
      //mask-size: 2560px 10000px;
      mask-size: 100% 80000px;
      mask-position: 50% 0;
    }

    &.wa-1 {
      @extend .red-shadow;
      z-index: 1;
    }

    &.wa-2 {
      @extend .yellow-shadow;
      z-index: 2;
    }

    &.wa-3 {
      @extend .blue-shadow;
      z-index: 3;
    }

    &.wa-4 {
      @extend .purple-shadow;
      z-index: 4;
    }

    &.wa-5 {
      z-index: 5;
      @extend .red-shadow;

    }

    &.wa-6 {
      z-index: 6;
      @extend .yellow-shadow;
    }

    &.wa-7 {
      z-index: 7;
      @extend .blue-shadow;
    }

    &.wa-8 {
      z-index: 8;
      @extend .purple-shadow;
    }

    &.wa-9 {
      z-index: 9;
      @extend .red-shadow;
    }

    &.wa-10 {
      z-index: 10;
      @extend .yellow-shadow;
    }

    &.wa-11 {
      z-index: 11;
      @extend .blue-shadow;
    }

    &.wa-12 {
      z-index: 12;
      @extend .purple-shadow;
    }

    &.nwa {
      mask: unset;
      box-shadow: inset #000 0 20px 50px -20px, #000 0 0 50px -25px;
      background: #9bca47;
      z-index: 21;

    }

    &.last {
      background: #64432e;
      z-index: 1000;
      height: 3000px;
    }

  }

  svg {
    display: none;
  }
}

