.asd-article, .asd-category {

  width: 90%;
  margin: 0 auto;
  //text-align: center;

  > * {
    padding: $spacing;
    box-sizing: border-box;
  }

  > .image {
    padding: $spacing;
    display: flex;
    align-items: center;
    justify-content: center;

    img, svg {
      max-height: 25vh;
    }

    img, svg, .fields-container {
      filter: drop-shadow(0 0 25px #000A);
      max-width: 100%;
      margin: 0 auto;

     @media screen and (min-width: $screen-laptop) {
         //max-height: 100%;
     }
    }

    .fields-container {
      img, svg {
        max-height: unset;
      }
    }

    .item-image {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    dl {
      width: 100%;

      dd {
        margin: 0;
      }
    }
  }

}

.asd-category {

  > * {
    width: 100%;
    display: block;

    @media screen and (min-width: $screen-laptop) {
      width: 40%;
    }
  }

  @media screen and (min-width: $screen-laptop) {
    @include df;
    @include df-aic;
    @include df-jcc;
    @include df-jcfs;

    width: 70%;
  }

  > .image {
    width: 100%;
    padding: $spacing 12.5%;
    box-sizing: border-box;
    margin: 0 auto;

    @media screen and (min-width: $screen-laptop) {
      width: 60%;
      padding: $spacing;
    }

    a {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  > .asd-article {
    width: 100%;

    @media screen and (min-width: $screen-computer) {
        width: 50%;
    }

    > * {
        width: 100%;
    }
  }

  .page-header, h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    text-align: center;
  }

  > div {
    order: 1;
  }
  > .image {
    order: 2;
  }

  &:nth-child(2n) {
    > div {
      order: 2;
    }
    > .image {
      order: 1;
    }

    > .asd-article {
        > div {
          order: 2;
          text-align: left;
        }

        > .image {
          order: 1;

          @media screen and (min-width: $screen-computer) {
              img, svg, .fields-container {
                  transform: translateX(-33%);
              }
          }

        }

    }
  }

  &.waves-container {
    margin-top: $spacing;

    @media screen and (min-width: $screen-laptop) {
      margin-top: 2* $spacing;
    }

    @media screen and (min-width: $screen-computer) {
      margin-top: 3 * $spacing;
    }

    &:first-child {
      margin-top: 0;
    }
  }

}

.asd-article {

  > * {
    text-align: right;
    background-color: #33333366;
    border-radius: 20px;
    width: 100%;
  }

  > .image {

    background: transparent;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    img, svg, .fields-container {
        @media screen and (min-width: $screen-computer) {
            transform: translateX(33%);
        }
    }

    .item-image {

        width: 100%;

        @media screen and (min-width: $screen-computer) {
          width: 150%;
          height: 120%;
        }


    }
  }

  @media screen and (min-width: $screen-laptop) {
      display: flex;
      align-items: center;
      width: 50%;

    > * {
        width: 66.66666%;
        order: 1;
    }

    > .image {
        width: 33.33333%;
        order: 2;
    }
  }

  &:nth-child(2n) {

    > div {
      order: 2;
      text-align: left;
    }

    > .image {
      order: 1;
    }
  }
}

.page-header {

  .asd-category {

    display: block;
    margin-bottom: 30px;

    > * {
      margin: 0 auto;
      width: 100%;
    }

    @media screen and (min-width: $screen-laptop) {
      > * {
        width: 80%;
        //transform: translateX(20%);
      }
      > .image {
        transform: unset;
      }
    }

    @media screen and (min-width: $screen-computer) {

      width: 90%;

      > * {
        width: 60%;
        text-align: left;
      }
      .image {
        width: 40%
      }

    }

  }

}

.cat-children {
  .asd-category {
    text-align: center;
  }
}

.intro-items {

  .asd-category {

    width: 100%;

    > div {
      @media screen and (min-width: $screen-laptop) {
        width: 75%;
      }
    }

    > .image {
        width: 100%;
        @media screen and (min-width: $screen-laptop) {
            width: 75%;
        }
    }
  }
}

.mod_asdarticleintro.asd-category{

  text-align: center;

    > * {
        order: 2;
    }

    > .image {
        order: 1;
        padding: 0;
    }
}

