.red-shadow {
  background: linear-gradient(330deg, #E61C4B 0%, #F9B129 100%);
}

.yellow-shadow {
  background: linear-gradient(60deg, rgb(254,166,0) -27.26%, rgb(239,219,41) 127.26%);
}

.blue-shadow {
  background: linear-gradient(240deg, rgb(0,0,97) -27.26%, rgb(0,101,255) 127.26%);
}

.purple-shadow {
  background: linear-gradient(60deg, #AB5EC2 -27.26%, #CC0011 127.26%);
}
