.boxtondo {
  text-align: center;
  @extend .h3;
}

div.asd-slideshow {
  width: 80% !important;
  margin: $spacing auto;
  box-shadow: #000 0 +10px 50px -15px;
  background-color: #fff;

  h3, h4 {
    text-align: center;
  }

  > .asd-vueper {
    background-color: #076b39;
    box-shadow: inset #000 0 0 50px -25px;
  }

  > .side-details {
    padding: $spacing;
    box-sizing: border-box;
    text-align: center;
    color: #111;

    display: flex;
    align-items: center;
    flex-wrap: wrap;

    p.readmore > a {
      border: 2px solid $color-primary;
      color: $color-primary;

    }

    h4 {
      font-size: 1.5rem;
    }

    * {
      color: inherit;
      width: 100%;
    }


    opacity: 1;
    transition: all 0.2s;
    &.sliding {
      opacity: 0;
    }
  }

  > div {
    width: 100%;
  }

  @media screen and (min-width: $screen-laptop) {
    display: flex;
    align-items: center;

    > .asd-vueper {
      width: 70%;
    }

    > .side-details {
      width: 30%;
    }
  }

.vueperslides {

  .vueperslide__content-wrapper {

    width: 100%;
    height: 100%;
    position: absolute;
    transform: unset !important;
    bottom: 0;
    top: unset !important;
    left: 0 !important;

    .vueperslide__content {

      height: 100%;
      div {
        height: 100%;
      }
    }
  }


  }
}

.asd_vueper.intro {
  .asd-slideshow {
    width: 100%;
    flex-wrap: wrap;

    > .side-details {
      width: 100%;
      background-color: #000;
      color: #fff;

      flex-wrap: nowrap;
    }

    > .asd-vueper {
      width: 100%;
    }
  }
}
.vueperslides--fixed-height {
  height: 210px;

  @media screen and (min-width: $screen-mobile) {
    height: 300px;
  }
}
