body > .wrapper > header {

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  min-height: 98vh;
  @media screen and (min-width: $screen-laptop+1) {
    min-height: 60vh;
  }

  > div {
    width: 80%;

    @media screen and (min-width: $screen-md) {
      width: 33.3%;
    }

    > * {
      width: 100%;
      color: $brand-white;
      text-align: center;
      display: block;
    }

    img {
      max-width: 60%;
      margin: 0 auto;
      @media screen and (min-width: $screen-laptop+1) {
        max-height: 30vh;
      }
    }

    span {
      display: block;
    }
  }

  > .background {

      @media screen and (min-width: $screen-md) {
        width: 66.6%;
      }

      img, svg {
        margin-left: -2px;
        max-width: unset;
        max-height: unset;
        width: 100%;

        @media screen and (max-width: $screen-md) {
          width: 230%;
          left: -130%;
          top: -20px;
          position: absolute;
        }
      }
  }
}
