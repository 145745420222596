// Customization
@import './variables';
@import './font';

@import 'mymini';

// Template style logic
@import '~@html/asd/src/css/variables';
@import '~@html/asd/src/css/mixins/00_all';

@import "~@html/asd/src/css/style/flex-style";
@import '~@html/asd/src/css/style/typography';

@import './style/typography';

@import './style/shadows';

// Template layout
@import 'layout/body';
@import 'layout/header';
@import 'layout/menu';
@import 'layout/main';
@import 'layout/footer';

@import 'layout/content_blog';
@import 'layout/content_article';

@import 'components/slideshow';
@import "../../../node_modules/vueperslides/dist/vueperslides.css";

// Template component
//@import '~slideout/index.css';
@import './components/slideout';
//@import '~@html/asd/src/css/layouts/asdback';
@import 'components/waves_background';
@import './components/menu_icon';

// External module CSS
//@import '~@html/asd/src/css/modules/form_contacts';
//@import '~@joomla/mod_asd_contact/src/modules/mod_asd_contact/asset/asd_contact';
@import 'layout/contact';

body {
  // Fix for 10px green background at end of page
  //background-color: #64432e;
}

.slideout-panel {
  will-change: unset;
}

.background {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0;

  top: 0;
  left: 0;

  > .image {
    display: flex;
    height: 100%;
    width: 100%;
  }
}
