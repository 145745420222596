body {

    overflow-x: hidden;

  > .wrapper {
    width: 100%;

    @include df;
    @include df-fww;
    position: relative;

    > * {
      width: 100%;
    }
  }

}
