.slideout-menu {
  width: 220px;
  transition: all 0.5s ease-in-out;

  @media screen and (min-width: $screen-laptop +1 ) {
    overflow: unset;
  }
}

.slideout-panel {
  @media screen and (min-width: $screen-laptop +1) {
    display: none;
  }

  &:before {
    content: '';
    display: block;
    background-color: rgba(0,0,0,0);
    transition: background-color 0.5s ease-in-out;
  }
}


.slideout-open {

  .slideout-menu {
    //z-index: +1;
    //transform: translateX(0);


    @media screen and (min-width: $screen-laptop+1) {
      transform: unset;
    }
  }

  .slideout-panel:before {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 99;
  }

}
