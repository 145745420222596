$font-path: "../font/";

@font-face {
  font-family: 'TexGyreAdventor';
  src: url('#{$font-path}/texgyreadventor/texgyreadventor-bold-webfont.eot');
  src: url('#{$font-path}texgyreadventor/texgyreadventor-bold-webfont.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}texgyreadventor/texgyreadventor-bold-webfont.woff2') format('woff2'),
  url('#{$font-path}texgyreadventor/texgyreadventor-bold-webfont.woff') format('woff'),
  url('#{$font-path}texgyreadventor/texgyreadventor-bold-webfont.ttf') format('truetype'),
  url('#{$font-path}texgyreadventor/texgyreadventor-bold-webfont.svg#texgyreadventorbold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'TexGyreAdventor';
  src: url('#{$font-path}texgyreadventor/texgyreadventor-bolditalic-webfont.eot');
  src: url('#{$font-path}texgyreadventor/texgyreadventor-bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}texgyreadventor/texgyreadventor-bolditalic-webfont.woff2') format('woff2'),
  url('#{$font-path}texgyreadventor/texgyreadventor-bolditalic-webfont.woff') format('woff'),
  url('#{$font-path}texgyreadventor/texgyreadventor-bolditalic-webfont.ttf') format('truetype'),
  url('#{$font-path}texgyreadventor/texgyreadventor-bolditalic-webfont.svg#texgyreadventorbold_italic') format('svg');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'TexGyreAdventor';
  src: url('#{$font-path}texgyreadventor/texgyreadventor-italic-webfont.eot');
  src: url('#{$font-path}texgyreadventor/texgyreadventor-italic-webfont.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}texgyreadventor/texgyreadventor-italic-webfont.woff2') format('woff2'),
  url('#{$font-path}texgyreadventor/texgyreadventor-italic-webfont.woff') format('woff'),
  url('#{$font-path}texgyreadventor/texgyreadventor-italic-webfont.ttf') format('truetype'),
  url('#{$font-path}texgyreadventor/texgyreadventor-italic-webfont.svg#texgyreadventoritalic') format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'TexGyreAdventor';
  src: url('#{$font-path}texgyreadventor/texgyreadventor-regular-webfont.eot');
  src: url('#{$font-path}texgyreadventor/texgyreadventor-regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}texgyreadventor/texgyreadventor-regular-webfont.woff2') format('woff2'),
  url('#{$font-path}texgyreadventor/texgyreadventor-regular-webfont.woff') format('woff'),
  url('#{$font-path}texgyreadventor/texgyreadventor-regular-webfont.ttf') format('truetype'),
  url('#{$font-path}texgyreadventor/texgyreadventor-regular-webfont.svg#texgyreadventorregular') format('svg');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'Merriweather';
  src: url('#{$font-path}merriweather/merriweather-bold-webfont.eot');
  src: url('#{$font-path}merriweather/merriweather-bold-webfont.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}merriweather/merriweather-bold-webfont.woff2') format('woff2'),
  url('#{$font-path}merriweather/merriweather-bold-webfont.woff') format('woff'),
  url('#{$font-path}merriweather/merriweather-bold-webfont.ttf') format('truetype'),
  url('#{$font-path}merriweather/merriweather-bold-webfont.svg#texgyreadventorbold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Merriweather';
  src: url('#{$font-path}merriweather/merriweather-bolditalic-webfont.eot');
  src: url('#{$font-path}merriweather/merriweather-bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}merriweather/merriweather-bolditalic-webfont.woff2') format('woff2'),
  url('#{$font-path}merriweather/merriweather-bolditalic-webfont.woff') format('woff'),
  url('#{$font-path}merriweather/merriweather-bolditalic-webfont.ttf') format('truetype'),
  url('#{$font-path}merriweather/merriweather-bolditalic-webfont.svg#texgyreadventorbold_italic') format('svg');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Merriweather';
  src: url('#{$font-path}merriweather/merriweather-italic-webfont.eot');
  src: url('#{$font-path}merriweather/merriweather-italic-webfont.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}merriweather/merriweather-italic-webfont.woff2') format('woff2'),
  url('#{$font-path}merriweather/merriweather-italic-webfont.woff') format('woff'),
  url('#{$font-path}merriweather/merriweather-italic-webfont.ttf') format('truetype'),
  url('#{$font-path}merriweather/merriweather-italic-webfont.svg#texgyreadventoritalic') format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Merriweather';
  src: url('#{$font-path}merriweather/merriweather-regular-webfont.eot');
  src: url('#{$font-path}merriweather/merriweather-regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}merriweather/merriweather-regular-webfont.woff2') format('woff2'),
  url('#{$font-path}merriweather/merriweather-regular-webfont.woff') format('woff'),
  url('#{$font-path}merriweather/merriweather-regular-webfont.ttf') format('truetype'),
  url('#{$font-path}merriweather/merriweather-regular-webfont.svg#texgyreadventorregular') format('svg');
  font-weight: normal;
  font-style: normal;
}
