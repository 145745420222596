body > .wrapper > footer {

  width: 100%;
  padding: $spacing $spacing 0;
  margin: 0;
  box-sizing: border-box;

  > * {
    width: 100%;
  }

  section.contatti {
    width: 80%;
    margin:$spacing auto;

    * {
      text-align: center !important;
    }
  }

  section.foglie {

    text-align: right;
    margin: 0;

    img, svg {
      max-height: 100%;
      margin: 0;
      max-width: 100%;

      @media screen and (min-width: $screen-laptop) {
        max-width: 800px;
      }
    }
  }

  > p {

    &:first-of-type {
      margin: 10vh 0 0;
    }

    //display: inline-block;
    text-align: center;
    color: white;
  }

}// (body >) footer
